import React from "react";

import Layout from "../components/Layout";
import View from "../components/Section";

const NotFound = (props) => (
	<Layout location={props.location}>
		<View title="Not Found">
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</View>
	</Layout>
);

export default NotFound;
